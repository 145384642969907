import React, { useEffect, useState } from "react";
import './Blogs.css'
import Articles from "../components/Articles";
import { GlobalMethods } from "../services/GlobalMethods";
import Spinner from "../components/utils/Spinner";
import SendEmail from "../components/utils/SendEmail";
import { useNavigate } from "react-router-dom";
import MetaTags from "../components/utils/MetaTags";

export default function Blogs({ type }) {
    const methods = new GlobalMethods()
    const navigate = useNavigate()
    GlobalMethods.current_sec = "blogs-section";
    const [isLoaded, setIsLoaded] = useState([])
    const [showSpinner, setShowSpinner] = useState(true)
    useEffect(() => {
        methods.snapTo(GlobalMethods.current_sec)
    }, [])
    useEffect(() => {
        let blogs_container = document.querySelector('#pills-tabContent')
        const observer = new MutationObserver((e) => {
            setShowSpinner(false)
        })
        const config = {
            childList: true
        }
        observer.observe(blogs_container, config)
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | My Fitness Coach" />
            <div className="bogs-page-container px-3">
                <section id="blogs-section">
                    <Spinner width="6%" duration={1000} show={showSpinner}/>
                    <>
                        <section className="blogs-hero d-flex flex-column align-items-center rg">
                            <div className="blogs-title ">
                                <h2 className="blogs-heading">Elevate Your Fitness Journey with Expert Insights and Inspiration</h2>
                            </div>
                            <div className="blogs-description">
                                <p className="blogs-description-txt"> Subscribe to our newsletter to never miss any new update about MyFitnessCoach, and stay up-to-date to new fitness blogs</p>
                            </div>
                            <SendEmail />
                        </section>
                        <section className="nav-container blogs-nav mt-5rem">
                            <div className="" >
                                <nav className="navbar navbar-expand-lg bg-light-primary" id="navbar">
                                    <div className="container-xl">
                                        <div className="d-flex flex-column align-items-start position-relative w-100" id="navbarNav ">
                                            <ul className=" nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" onClick={(e) => navigate('/blogs/all')}>
                                                    <a className={"cursor-pointer nav-link " + ((type == "all") ? " active" : "")} id="pills-all_article-tab">All Articles</a>
                                                </li>
                                                <li className="nav-item" onClick={(e) => navigate('/blogs/workout')}>
                                                    <a className={"cursor-pointer nav-link " + ((type == 'workout') ? 'active' : '')} id="pills-workout-tab">Workouts</a>
                                                </li>
                                                <li className="nav-item" onClick={(e) => navigate('/blogs/recipe')}>
                                                    <a className={"cursor-pointer nav-link " + ((type == 'recipe') ? 'active' : '')} id="pills-recipe-tab">Recipes</a>
                                                </li>
                                                <li className="nav-item" onClick={(e) => navigate('/blogs/health')}>
                                                    <a className={"cursor-pointer nav-link " + ((type == 'health') ? 'active' : '')} id="pills-fatloss-tab">Health</a>
                                                </li>
                                            </ul>
                                            <span className="nav-vertical-line w-100"></span>
                                            <div className="tab-content width-sm" id="pills-tabContent" style={{width: '100%'}}>
                                                <div className={"tab-pane fade " + ((type == 'all') ? 'active show' : '')} id="pills-all_article"
                                                >
                                                    {
                                                        (type=='all'||isLoaded.includes('all'))&&
                                                        <Articles isLoaded={isLoaded} setIsLoaded={setIsLoaded} category="all" />
                                                    }
                                                </div>
                                                <div className={"tab-pane fade " + ((type == 'workout') ? 'active show' : '')} id="pills-workout">
                                                    {
                                                        (type=='workout'||isLoaded.includes('workout'))&&
                                                        <Articles isLoaded={isLoaded} setIsLoaded={setIsLoaded} category="workout" />
                                                    }
                                                </div>
                                                <div className={"tab-pane fade " + ((type == 'recipe') ? 'active show' : '')} id="pills-recipe">
                                                    {
                                                        (type=='recipe'||isLoaded.includes('recipe'))&&
                                                        <Articles isLoaded={isLoaded} setIsLoaded={setIsLoaded} category="recipe" />
                                                    }
                                                </div>
                                                <div className={"tab-pane fade " + ((type == 'health') ? 'active show' : '')} id="pills-fatloss">
                                                    {
                                                        (type=='health'||isLoaded.includes('health'))&&
                                                        <Articles isLoaded={isLoaded} setIsLoaded={setIsLoaded} category="health" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </section>
                        <section className="blogs-content-top">
                        </section>
                        <section className="blogs-content-bottom"></section>
                    </>
                </section>
            </div>
        </>
    )
}