import React from 'react';
import { GlobalMethods } from '../../services/GlobalMethods';
import { useState } from 'react';
import { servicex } from '../../services/Api';
import { Alert, Snackbar } from '@mui/material';

export default function SendEmail() {
  const methods = new GlobalMethods()
  const service = new servicex()
  const [email, setEmail] = useState("")
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("email sent successfully!")
  const [severity, setSeverity] = useState("success")

  const handleSubmit = (event) => {
    event.preventDefault()
    if(!methods.validateEmail(email)) {
      setSeverity("warning")
      setMessage("Enter a valid email!")
      setOpen(true)
      return;
    }
    service.sendEmail(email).then(x => {
      if (x?.data) {
        document.querySelector("input#email").value = ""
        setSeverity("success")
        setMessage("email sent successfully!")
        setOpen(true)
        setEmail("");
      }
    })
      .catch(error => {
        console.log("Error: ", error)
      })
  }
  return (
    <>
      <div className='subscribe-buttons'>
        <form className='subscribe-form' onSubmit={handleSubmit}>
          <input className='subs-email' type="text" name="email" id="email" placeholder="Email" autoComplete="off"
            onChange={(event) => setEmail(event.target.value)} />
          <input className='subs-button' type="submit" value="Submit" />
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>{setOpen(false)}}>
        <Alert onClose={()=>{setOpen(false)}} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert> */}
    </>
  )
}