import React from 'react';
import "./Spinner.css";
import { useState } from 'react';
import { useEffect } from 'react';
export default function Spinner(props) {
  const {width, duration, show} = props;
  const [active, setActive] = useState(true);
  
  useEffect(()=>{
    if(!show) {
      setActive(false)
    }
  },[show])
  useEffect(()=>{
    setTimeout(() => {
      if(window.document.readyState === 'complete') {
        setActive(false);
      } else {
        window.onload = ()=>{
          setActive(false)
        }
      }
    }, duration);
    setTimeout(() => {
      setActive(false)
    }, 5000);
  })
  return (
    <>
    <div className={'custom-spinner'+" "+(active?'active':'')}>
      <svg fill="none" viewBox="0 0 66 66" style={{color: "#4F30CC", overflow: "visible", minWidth: "100px", width: (width || "100px")}}>
        <circle cx="33" cy="33" fill="none" r="28" stroke="#f2f2f2" strokeWidth="4"></circle>
        <circle cx="33" cy="33" fill="none" r="28" stroke="currentColor" strokeDasharray="1, 174" 
          strokeDashoffset="306" strokeLinecap="round" strokeWidth="4" 
          className='custom-spinner-circle'></circle>
      </svg>
    </div>
    </>
  )
}