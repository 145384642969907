import React, { useEffect } from 'react';
import { GlobalMethods } from '../services/GlobalMethods';
import MetaTags from '../components/utils/MetaTags';
export default function PrivacyPolicy() {
    const methods = new GlobalMethods()
    GlobalMethods.current_sec = "privacy-policy";
    useEffect(() => {
        setTimeout(() => {
            methods.snapTo(GlobalMethods.current_sec)
        }, 500);
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | My Fitness Coach"/>
            <main className="px-responsive-5">
                <section id="privacy-policy" className='about-who-we-are'>
                    <div style={{ backgroundColor: "#37395D;" }}>
                        <div className="secondary-wrapper blogs-container">
                            <div className="container container-padding-fluid">
                                <p className='blogs-heading text-start' >
                                    Privacy Policy for MyFitnessCoach
                                </p>
                                <p className='card-mid-p'>At MyFitnessCoach, we are dedicated to protecting the privacy of our users and ensuring the security of their personal data. This Privacy Policy outlines the types of data we collect, why we collect it, how we use it, and how we ensure its security. By using MyFitnessCoach, you agree to the practices described in this policy. We encourage you to read this document carefully to understand how your data is handled and your rights in relation to it.
                                </p>
                                <p className="blogs-h2"><b>Data Collection and Purpose</b></p>
                                <p className='card-mid-p'>MyFitnessCoach collects different types of personal and health-related data to enhance your fitness experience and provide personalized services. The data we collect includes, but is not limited to:
                                </p>
                                <p className="blogs-h3"><b>a: Personal Information</b></p>
                                <p className='card-mid-p'>During the signup process, we collect basic information such as:
                                </p>
                                <p className='card-mid-p'>
                                <b>Name: </b> This is used to create a personalized experience within the app.
                                </p>
                                <p className='card-mid-p'>
                                <b>Email Address: </b> Your email is required for account creation, password recovery, and important notifications about app updates or security alerts.
                                </p>
                                <p className='card-mid-p'>
                                <b>Age and Gender: </b> These details help tailor fitness plans to your needs, ensuring that the exercises and coaching advice are age-appropriate, gender-specific (if needed), and regionally relevant.
                                </p>
                                <p className='card-mid-p'>
                                <b>Password: </b> This secures your account, and we strongly recommend using a strong and unique password for your protection.
                                </p>
                                <p className="blogs-h3"><b>b: Health and Fitness Data</b></p>
                                <p className='card-mid-p'>We gather health-related information from users to create a personalized fitness program. This data includes:
                                </p>
                                <p className='card-mid-p'>
                                <b>Height and Weight: </b> Necessary for calculating metrics such as Body Mass Index (BMI) and tailoring fitness goals.
                                </p>
                                <p className='card-mid-p'>
                                <b>Fitness Goals: </b> We ask about your specific fitness objectives, such as weight loss, muscle gain, or endurance improvement, to offer goal-specific workout programs and nutrition advice. 
                                </p>
                                <p className='card-mid-p'>
                                <b>Exercise and Activity Levels: </b> This includes data on the types and intensity of exercises you perform, which helps us track progress and make recommendations based on your current fitness level.
                                </p>
                                <p className="blogs-h3"><b>c: Wearable Integration Data</b></p>
                                <p className='card-mid-p'>If you link a wearable device (e.g., a smartwatch, fitness tracker, etc.) with MyFitnessCoach, we collect and process additional data to provide a more accurate and holistic view of your health and fitness. Wearable data we may collect includes:
                                </p>
                                <p className='card-mid-p'>
                                <b>Heart Rate: </b> This data allows us to monitor your workout intensity, providing insights such as whether you're training in your optimal heart rate zone for fat burning or endurance. 
                                </p>
                                <p className='card-mid-p'>
                                <b>Sleep Patterns: </b> We use sleep data to analyze your recovery time, offering suggestions for rest and improvement of your overall well-being.  
                                </p>
                                <p className='card-mid-p'>
                                <b>Heart Rate Variability (HRV):</b> HRV is a key indicator of your stress levels and physical readiness. This data helps in adjusting your workouts based on how well your body is responding to training.
                                </p>
                                <p className='card-mid-p'>
                                <b>Physical Activity: </b> This includes step counts, distance traveled, active minutes, and calories burned. By monitoring your daily activity, we can provide feedback on your movement patterns and how they contribute to your overall fitness.
                                </p>
                                <p className="blogs-h3"><b>d: Optional Data</b></p>
                                <p className='card-mid-p'>
                                 We may offer features that allow you to share more information with us to enhance your experience, such as meal tracking, body measurements, or progress photos. However, providing this data is entirely optional.
                                </p>
                                <p className="blogs-h2"><b>How We Use Your Data</b></p>
                                <p className='card-mid-p'>The data collected through MyFitnessCoach is used to ensure you receive a tailored, personalized fitness experience. Specifically, we use your data in the following ways:</p>
                                {/* yhan sy start krna hy monday ko  */}
                                <p className='card-mid-p'><b>Personalized Fitness Plans: </b> Based on your input and health data, we generate customized workout programs and coaching advice that align with your fitness goals.
                                </p>
                                <p className='card-mid-p'><b>Progress Tracking and Insights: </b> Data from your workouts, wearable devices, and fitness tracking helps us to provide insights on your progress over time. We present this information in easy-to-read formats, so you can see improvements in your physical activity, endurance, strength, and recovery.
                                </p>
                                <p className='card-mid-p'><b>Motivation and Goal Setting :</b> Your progress and activity data are used to provide motivational tips and milestones, helping you stay on track to achieve your goals.
                                </p>
                                <p className='card-mid-p'><b>Wearable Data Insights: </b> We use wearable integration data, such as heart rate and sleep metrics, to offer recommendations on how to optimize your performance and recovery. This data enhances the accuracy of our feedback and fitness plans.
                                </p>
                                <p className='card-mid-p'><b>User Experience Improvement: </b> We analyze user data in an anonymized and aggregated manner to better understand how users interact with our app, enabling us to make improvements and offer new features.
                                </p>
                                <p className='blogs-h2'><b>Data Security</b></p>
                                <p className='card-mid-p'>We take your privacy and the security of your personal information seriously. To safeguard your data, we implement industry-standard security measures including:</p>
                                <p className='card-mid-p'><b>Encryption: </b> All sensitive data, such as passwords and health information, is encrypted in transit and at rest. This ensures that unauthorized parties cannot access your data.</p>
                                <p className='card-mid-p'><b>Access Control: </b> Only authorized MyFitnessCoach personnel who need access to your data to provide the services outlined in this policy are granted such access. Access is strictly monitored and controlled.</p>
                                <p className='card-mid-p'><b>Secure Storage: </b> : We store your data on secure servers that comply with industry security standards to prevent unauthorized access or data breaches.</p>
                                <p className='card-mid-p'>While we implement these safeguards, it is important to note that no system is 100% secure. In the unlikely event of a data breach, we will promptly notify affected users and take appropriate measures to mitigate the risks.</p>                              
                                <p className='blogs-h2'><b>Cookies</b></p>
                                <p className='card-mid-p'>Like any other website, MyFitnessCoach uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                                <p className='blogs-h2'><b>Third-Party Data Sharing</b></p>
                                <p className='card-mid-p'>We respect your privacy and do not sell, trade, or rent your personal data to third parties. However, in certain circumstances, we may share your data with third-party service providers who help us operate MyFitnessCoach. These providers are bound by strict data privacy agreements, ensuring your data is only used for specific purposes related to the functioning of our app.</p>
                                <p className='card-mid-p'><b>Third-Party Integrations: </b> If you choose to integrate with third-party services (such as wearable device providers or other fitness tracking platforms), we will share data with them only as needed and with your consent.</p>
                                <p className='card-mid-p'><b>Legal Compliance: </b> We may also disclose your data if required by law or if we believe such action is necessary to comply with legal obligations, protect the safety of users, or defend the rights of MyFitnessCoach.</p>
                                <p className='blogs-h2'>Your Data Rights</p>
                                <p className='card-mid-p'>You have the following rights concerning your personal data:</p>
                                <p className='card-mid-p'><b>Access and Update: </b> You can access, review, and update your personal data at any time by logging into your account or contacting our support team.</p>
                                <p className='card-mid-p'><b>Data Portability: </b> Upon request, we can provide you with a copy of your personal data in a structured, commonly used, and machine-readable format.</p>
                                <p className='card-mid-p'><b>Data Deletion: </b> You have the right to request the deletion of your personal data at any time. However, please note that certain data may be retained for legal compliance, dispute resolution, or for purposes essential to the app’s functioning.</p>
                                <p className='card-mid-p'><b>Wearable Data Management: </b> If you no longer wish to share data from your wearable device, you may disconnect it from the app through the settings at any time.</p>
                                <p className='blogs-h2'>Retention of Data</p>
                                <p className='card-mid-p'>We retain your data only for as long as is necessary to fulfill the purposes for which it was collected or as required by law. Once your data is no longer needed, we will securely delete it in accordance with our data retention policies.</p>
                                <p className='blogs-h2'>Changes to the Privacy Policy</p>
                                <p className='card-mid-p'>From time to time, we may update this Privacy Policy to reflect changes in our data practices, legal obligations, or the features of the app. When we make significant changes, we will notify you within the app and provide an opportunity to review the updated policy. Your continued use of MyFitnessCoach after such updates will constitute acceptance of the changes.</p>

                                <p className='blogs-h2'><b>Contact Information</b></p>
                                <p className='card-mid-p'>If you have any questions, concerns, or requests regarding this Privacy Policy or how your data is handled, please contact our support team at:&nbsp;<a href="https://mail.google.com/mail/u/0/?fs=1&to=info@myfitnesscoach.fit&su=&body=&tf=cm" target='_blank'>info@myfitnesscoach.fit</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>
    )
}