import React from "react";
import { Box, Button, Typography } from '@mui/material';
import { NavLink } from "react-router-dom";
import { GlobalMethods } from "../services/GlobalMethods";
const primary = "#37395D" // #f44336
export default function Unsubscribe(props) {
  const methods = new GlobalMethods();
  const {inside} = props;
  GlobalMethods.current_sec = "unsub-page"
  return (
    <>
      <section id="unsub-page"></section>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: (inside?'400px':'100vh'),
          marginTop: (inside?'-4rem':''),
          marginBottom: (inside?'-5rem':''),
          backgroundColor: primary,
        }}
      >
        <Typography variant="h1" style={{ color: 'white' }}>
        &#10004;
        </Typography>
        <Typography variant="h6" style={{ color: 'white' }}>
          Your email has been unsubscribed!
        </Typography>
        {
          !inside&&
          <Button variant="contained" className="mt-3">
            <NavLink to="/" className={"nav-link blogs-nav-item menu-font text-white"}
              data-section="hero-section" onClick={methods.handleNavigation}
            >Back Home
            </NavLink>
          </Button>
        }
      </Box>
    </>)
}