import * as React from "react";
import '../utils/VerticalCarousel.css';
import { Grid } from "@mui/material";
export default function VerticalCarousel(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeElement, setActiveElement] = React.useState(0);
  const handleClick = (value) => {
    setActiveStep(value)
    setTimeout(() => {
      setActiveElement(value)
    }, 800);
  }
  return (
    <>
      <Grid container spacing={2} className="vertical-carousel">
        <Grid item xs={1}>
          <div className="vertical-nav w-100 position-relative">
            <div className={"vertical-nav-item" + (activeStep == 0 ? " active" : "")}
              onClick={() => { handleClick(0) }}>
              <div className="custom-dot"></div>
              <div className="vertical-nav-title">01</div>
            </div>
            <div className={"vertical-nav-item" + (activeStep == 1 ? " active" : "")}
              onClick={() => { handleClick(1) }}>
              <div className="custom-dot"></div>
              <div className="vertical-nav-title">02</div>
            </div>
            <div className={"vertical-nav-item" + (activeStep == 2 ? " active" : "")}
              onClick={() => { handleClick(2) }}>
              <div className="custom-dot"></div>
              <div className="vertical-nav-title">03</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={11} className="content-container">
          <div className={"vertical-carousel-content" + (activeElement == 0 ? " active" : "")
            + " " + (activeStep == 0 ? "slide-in" : "slide-out")}>
            <div className="stepper1">
              <div className="stepper1-body d-flex align-items-start justify-content-center">
                <div className="stepper-left col-xl-5 col-sm-12 d-flex flex-column rg px-3 slide-top">
                  <h3 className="card-inner-title text-start">Enter your details
                    according to your
                    goals</h3>
                  <span className="stepper-row-line"></span>
                  <p className="plan-txt-md">In order to ensure every individual's needs are met, the app generates diet and workout plans based on his or her body type.</p>
                </div>
                <div className="stepper-right p-3  col-xl-7 col-sm-12 position-relative">
                  <img  className="q-mark position-absolute" src="assets/Q-mark.png" alt="question mark" />
                  <div className="s1-body-wraper pt-3 position-relative">
                    <img  className="slide-bottom details-list position-relative drop-shadow" src="assets/details-list.svg" alt="details list" />
                    <img  className="slide-left details-list2 position-absolute drop-shadow" src="assets/details-list2.svg" alt="personal details list" />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className={"vertical-carousel-content" + (activeElement == 1 ? " active" : "")
            + " " + (activeStep == 1 ? "slide-in" : "slide-out")}>
            <div className="stepper2">
              <div className="stepper2-body d-flex align-items-start justify-content-center">
                <div className="stepper2-left col-xl-5 col-sm-12 d-flex flex-column rg px-3 slide-top">
                  <h3 className="card-inner-title text-start">Select fitness
                    plan & Meal
                    plans</h3>
                  <span className="stepper-row-line"></span>
                  <p className="plan-txt-md">Provides diet and exercise plans that can be customized to meet individual needs while maintaining a healthy diet.</p>
                </div>
                <div className="stepper2-right col-xl-7 col-sm-12 ">
                  <div className="s2-body-wraper pt-3 position-relative">
                    <img className="grp-pic-sm w-100 slide-right" src="../assets/grp-work-plan+diet.webp" alt="myfitnesscoachapp features" />
                    <img className="slide-bottom eat2fit position-absolute" src="assets/eat2fit.png" alt="eat to fit" />
                    <img className="slide-left work2fit position-absolute" src="assets/work2fit.png" alt="work to fit " />
                    <img className="slide-top warm2fit position-absolute" src="assets/warm2fit.png" alt="warm up exercises" />
                    <img className="slide-left diet2fit position-absolute" src="assets/diet2fit.png" alt="diet to fit" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"vertical-carousel-content" + (activeElement == 2 ? " active" : "")
            + " " + (activeStep == 2 ? "slide-in" : "slide-out")}>
            <div className="stepper3">
              <div className="stepper3-body d-flex align-items-start justify-content-center">
                <div className="stepper3-left col-xl-5 col-sm-12 d-flex flex-column rg px-3 slide-top">
                  <h3 className="card-inner-title text-start">Keep track of
                    your daily
                    progress</h3>
                  <span className="stepper-row-line"></span>
                  <p className="plan-txt-md">Keep a daily or weekly record of your progress. This can be in the form of your calorie intake, workouts, or the number of steps you take. Establish your own fitness goals and follow them to achieve the best results.</p>
                </div>
                <div className="stepper3-right col-xl-7 col-sm-12 ">
                  <div className="s3-body-wraper pt-3 position-relative">
                    <img  className="step3-img-sm w-100 slide-right" src="../assets/step3-img-sm.png" alt="question mark on background" />
                    <img  className="slide-left daily-goals position-absolute" src="assets/daily-goals.svg" alt="daily goals" />
                    <img  className="slide-top step-chart position-absolute" src="assets/step-chart.svg" alt="step chart" />
                    <img  className="slide-bottom calories-detail position-absolute" src="assets/calories-detail.svg" alt="calories chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
