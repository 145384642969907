import React from 'react';
import "./Rocket.css";
import { useState, useEffect } from 'react';

export default function Rocket(props) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', (event) => {
      if (window.scrollY > 1000) {
        setActive(true)
      } else {
        setActive(false)
      }
    })
  })
  return (
    <>
      <div className={'custom-rocket' + " " + (active ? 'active' : '')}
        onClick={() => window.scrollTo(0, 0)}>
        <img  src='/assets/onTop.svg' style={{ width: '3rem' }} alt='bottom to top scroller icon' />
      </div>
    </>
  )
}