import React, { useEffect } from 'react';
import { GlobalMethods } from '../services/GlobalMethods';
import MetaTags from '../components/utils/MetaTags';
export default function Terms() {
    const methods = new GlobalMethods()
    GlobalMethods.current_sec = "privacy-policy";
    useEffect(() => {
        setTimeout(() => {
            methods.snapTo(GlobalMethods.current_sec)
        }, 500);
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | My Fitness Coach"/>
            <main className="px-responsive-5">
                <section id="privacy-policy" className='about-who-we-are'>
                    <div style={{ backgroundColor: "#37395D;" }}>
                        <div className="secondary-wrapper blogs-container">
                            <div className="container container-padding-fluid">
                                <p className='blogs-heading text-start'>
                                    Terms & Conditions
                                </p>
                                <p className='card-mid-p'><b>PLEASE READ THESE TERMS OF USE CAREFULLY.
                                </b></p>
                                <p className='card-mid-p'><b>These Terms of Usage cover crucial information about the Platform and the applicable fees. They include, in particular, information regarding any possible changes in these Terms of Use and Platform (refer to Clause 8) and the renewal and purchase of memberships on the Platform (refer to Clause 2), and the limitations of responsibility (refer to Clause 7.1).
                                </b></p>
                                <p className='blogs-h3'><b>Welcome to our MYFITNESSCOACH App!</b></p>
                                <p className='card-mid-p'>These terms of use and any other terms and documents expressly incorporated herein, each as may be amended, supplemented, or replaced from time to time (together, the "Terms of Use") apply to your use of (and subscription to) the MYFITNESSCOACH App, MYFITNESSCOACH mobile application ("MYFITNESSCOACH App"), blog, forum made available to you by MYFITNESSCOACH (at https://myfitnesscoach.fit and via the MYFITNESSCOACH App, "Forum"), online shop or one of our other products or services, all of which are part of MYFITNESSCOACH's Platform (the <b>"Platform"</b>).
                                </p>
                                <p className='card-mid-p'>These Terms of Service are signed between you as the person (referred to as "User," "you," or "you're") and The My Fitness Coach LLC and its affiliates (referred to as "MYFITNESSCOACH," "we," "us" or "our") and override all terms, conditions, and restrictions for services you may have previously negotiated with MYFITNESSCOACH regarding the Platform.</p>
                                <p className='card-mid-p'><b>Access to and usage of the Platform signifies your acceptance to be bound by the Terms and Conditions of Use.</b> The offering of the Platform to you is contingent upon accepting these terms of use. If you do not consent to these Conditions of use, you should not use or access the Platform.
                                    For more details about MYFITNESSCOACH's privacy policy, please refer to our Privacy Policy available at the following webpage: _____________
                                </p>
                                <p className='blogs-h2'><b>USER Eligibility
                                </b></p>
                                <p className='card-mid-p'>When using or accessing the Platform, you affirm that you are over the age of legal in your jurisdiction and not younger than 16 years old and have all the ability, power, and authority to accept the Terms of Service and have not previously been taken off or banned from using the Platform.</p>
                                <p className='blogs-h3'><b>User Account Creation</b></p>
                                <p className='card-mid-p'>To make use of certain features on the Platform, You will be required to sign up for an account as a user (each is referred to as a  <b>"User Account"</b>) by submitting your name and initials along with your email address, password, date of birth, and any other information we may need from time-to-time. Make sure to provide current and accurate information when registering Your User Account.</p>
                                <p className='card-mid-p'><b>MYFITNESSCOACH could, in their sole discretion:</b></p>
                                <ul className='blogs-list'>
                                    <li>Limit the number of user accounts you are able to create or keep;</li>
                                    <li>provide additional conditions and guidelines to allow the creation or maintenance of User Accounts.
                                    </li>
                                    <li>We will not create a User Account on behalf of you.</li>
                                </ul>
                                <p className='blogs-h3'><b>Security </b></p>
                                <p className='card-mid-p'>LWhen you use the User Account, you are accountable for securing your User Account login credentials as well as all actions that occur within the User Account and for any other actions related to the User Account (with and without consent). MYFITNESSCOACH is not liable for any damage or loss that is a result of the unauthorized usage of your User Account as a result of your inability of you to safeguard your access credentials.
                                    It is not recommended to divulge the credentials to access the user Account (including the passwords) with anyone else or permit anyone else to gain access to the User Account. You must immediately notify the MYFITNESSCOACH team in writing at contact@myfitnesscoach.fit of any unauthorized use of your User Account or any other breaches of security.</p>
                                <h2 className='blogs-h2'>SUBSCRIPTION SERVICE</h2>
                                <p className='blogs-h3'><b>Subscription</b></p>
                                <p className='card-mid-p'>You can choose to subscribe to the Platform either on a monthly, quarterly, or annual basis or for such other periods that MYFITNESSCOACH may offer from time to time, and the applicable fees are set forth on the MYFITNESSCOACH webpage (https://myfitnesscoach.fit) or on the applicable app store from which the MYFITNESSCOACH App can be downloaded.
                                    The eligibility for any promotion or discounts is determined at the time you sign up and cannot be altered over the course of the subscription. You are accountable for understanding and reading particular terms and conditions that apply to any discount or promotion.
                                    MYFITNESSCOACH retains the option to change and alter the fees applicable to subscribe (including the charges listed in the respective app stores that you use to sign up to (MYFITNESSCOACH's Platform) and the different subscription plans offered at any point at its discretion. Any revisions or modifications to the fees will be applied retroactively for any new subscription you enter upon the date when the fee revision is made or revision or update.)
                                </p>
                                <p className='blogs-h3'><b>Trials for free</b></p>
                                <p className='card-mid-p'>We can offer a free trial for a specific duration, decided by MYFITNESSCOACH at its discretion. Unless stated otherwise by MYFITNESSCOACH regarding certain offers, trial offers only are available for new MYFITNESSCOACH users. MYFITNESSCOACH can end any trial subscription at any time if we discover that the user has been a user of a trial account on an account with a different user or with an alternative email address. In addition, any attempt to sign up for another trial at no cost with the same User Account or with an email account that is identical to the one you have registered for could result in being charged the relevant subscription fees.
                                </p>
                                <p className='blogs-h3'><b>Payment details</b></p>
                                <p className='card-mid-p'>You can pay for the charges for your subscription via the MYFITNESSCOACH App by using either a credit or debit account, PayPal payments, Stripe, or via your account in the app store for using the MYFITNESSCOACH app (i.e., via in-app purchase).</p>
                                <p className='card-mid-p'>The cost that you pay for the subscription and the currency that the subscription is due will be mentioned in the process of ordering and can vary depending on the country you reside in (as determined by the IP address of the device that connects to this Platform).
                                </p>
                                <p className='card-mid-p'>If you decide to purchase a subscription, you have to provide us with precise and complete information about your payment. By providing payment information by submitting payment details, you confirm that you have the right to buy a membership with the payment information. If we don't receive an authorization for payment or if the authorization is later canceled or canceled, we can immediately end or suspend account access. MYFITNESSCOACH has the authority to call the bank/payment service provider that issued the subscription and/or law enforcement officials or other relevant third parties in the event that there is any suspicious activities.</p>
                                <p className='card-mid-p'>Alongside the monthly subscription fees you're paid, certain credit card issuers and banks may charge a foreign transaction fee for transactions that occur outside of the country or in an international currency. The customer is responsible for paying any fees incurred</p>
                                <p className='blogs-h3'><b>Automatic renewal for subscription</b></p>
                                <p className='card-mid-p'>Your subscription will renew automatically unless you cancel it at minimum (24) hours prior to the expiration date of your next billing time. You can change your mind at any point by accessing your account page and then following instructions to end your subscription. In the event of cancellation, however, you'll be able to use the subscription services available on the Platform for the remaining time you paid for.</p>
                                <p className='blogs-h3'><b>USE OF THE PLATFORM</b></p>
                                <p className='card-mid-p'>When you use the App, you agree on the fact that you won't utilize the Platform to do some of the reasons listed below reasons:</p>
                                <ul className='blogs-list'>
                                    <li>Illegal Activity is any type of activity that (i) is based on the proceeds of any illegal or unconstitutional activity; (ii) publishes, or distributes any illegal or untrue information or material or (iii) or otherwise is in violation of, or could infringe, any civil and common laws, statutes and treaties, subordinate legislation regulations, directives, or decisions and by-laws. Ordinances, circulars, codes or orders, notices or other orders, decrees, injunctions, decisions, and resolutions of any quasi-government, government legal, administrative, or regulatory agency, court, or other association under that MYFITNESSCOACH or the user is legally bound by any jurisdiction that applies to the use and access on the Platform <b>( "Applicable Laws")</b>.</li>
                                    <li>Unauthorized use is any act that (i) disrupts or disrupts, negatively impacts, or prevents users from using or enjoying this Platform; (ii) introduces to the Platform any virus, malware trojan horse or logic bombs, or another program that may cause a technical malfunction, glitch or failure, delay, default, or Security breach (iii) efforts to get access that is not authorized, either via password mining or any other means to other user Accounts and computing systems or networks that are connected with the Trading Platform; (iv) compromise any security, integrity or safety of computer systems or networks that the Platform is hosted. (v) efforts to alter the Platform, copy, replicate, reverse engineer, or disassemble or decompile the Platform and the programs that provide Platform services; Platform; (vi) uses the User Account details of another user for access to or using the Platform or (vii) transfer access to or rights for your user Account to a third-party.
                                    </li>
                                    <li>abusive acts is any action that (i) is a slander, insults or harasses, extorts stalks, threatens, or violates the rights of law (such as including, but not limited to rights to privacy publicity, intellectual property and privacy) of anyone else; (ii) incites, encourages, threatens, facilitates or promotes hatred and racial indifference or violence towards other person or (iii) gathers or collects data from the Platform regarding other Users such as Mobile numbers, email addresses and credit card numbers.</li>
                                    <li>Fraud Any act that (i) attempts to fraudulently defraud MYFITNESSCOACH or anyone else or (ii) gives incorrect, false, or incorrect data to MYFITNESSCOACH.</li>
                                </ul>
                                <p className='blogs-h3'><b>AVAILABILITY OF SERVICES, SECURITY</b></p>
                                <p className='card-mid-p'>MYFITNESSCOACH will make reasonable efforts to make sure it is available. The Platform is accessible to you. However, your access to Platform can be interrupted occasionally due to maintenance requirements or technical issues, network or overloads, or other events that are outside of the control of MYFITNESSCOACH. MYFITNESSCOACH will employ commercially-justifiable efforts to minimize interruptions to the Platform. However, it will not assume any obligation in the event that there is a time when the Platform or any portion of it is not available at any point or for any length of time.
                                </p>
                                <p className='card-mid-p'>You acknowledge and accept that you are accountable for implementing the appropriate procedures and checkpoints to meet your specific requirements regarding security against virus and the quality of the data output and also for maintaining a mechanism outside of the Platform to reconstruct any data that is lost.</p>
                                <p className='card-mid-p'>You acknowledge that MYFITNESSCOACH will not be held to any responsibility or liability whatsoever for (a) connecting to an internet connection via the Platform or any technical issues or system malfunctions, as well as security breach; (b) the hardware that you use to connect to the Platform (including with respect to malware, viruses as well as any other inappropriate material) and the accuracy and correct storage of any data that is associated with the Platform which can be stored by your personal hardware, or (c) any charges you might incur access the Internet in order to fulfill the purpose of accessing or using the Platform.</p>
                                <p className='card-mid-p'>You must immediately inform the MYFITNESSCOACH team when you discover any aspect of the Platform that is not functioning properly or if you have any other issues or connectivity issue that impacts your access to or access to the Platform.</p>
                                <p className='card-mid-p'>Users might also be forced to download and install any updates for the MYFITNESSCOACH Application to keep accessibility to the Platform as well as its features. Failure to follow this procedure could cause certain services through the Platform to be temporarily unavailable to the user until the update is downloaded and installed.</p>

                                <p className='blogs-h3'><b>INTELLECTUAL PROPERTY, USE LICENCE</b></p>
                                <p className='card-mid-p'>MYFITNESSCOACH's ownership rights to the Platform
                                </p>
                                <p className='card-mid-p'>All trademarks, patents, trade names, logos the rights to domain names copyrights, moral right design rights databases rights, rights in unreleased or secret information (such as trade secrets, know-how and invents (whether they are patentable or not) as well as other related Intellectual Property Rights (whether legally registered, or otherwise)) as well as applications to these rights that are available anywhere in the globe <b>(collectively, "Intellectual Property Rights")</b> within the Platform and the content published on the Platform.
                                </p>
                                <p className='blogs-h3'><b>The user's license to use the Platform.</b></p>
                                <p className='card-mid-p'>You do not have any rights to use or are permitted to use any of Myfitnessscoach's Intellectual Property Rights that are not according to these Conditions of Use and subject to the following terms and conditions:</p>
                                <ul className='blogs-list'>
                                    <li>You are granted a limited personal, non-transferable, irrevocable, non-sublicensable license to use and access this Platform (or any portion of it or its contents) for your personal use. You may not reproduce, copy the Platform, upload, republish the Platform, re-post, alter the Platform, transmit, distribute or make use of or otherwise use the Platform (or any portion of its contents) in any manner for any non-personal, public or commercial purposes without prior written permission from MYFITNESSCOACH</li>
                                    <li>you are not allowed to remove or alter any trademark, copyright, or other notices of proprietary nature which have been placed on any area of the Platform as well as</li>
                                    <li><p>You are not allowed to use robots, data mining, or similar data-gathering methods.</p><p>MYFITNESSCOACH has the power to review your usage on the Platform and modify or cancel your license or use of the Platform at any time for any reason. Your license will expire after the expiration or the termination of the user account..</p></li>
                                </ul>
                                <p className='blogs-h3'><b>THIRD-PARTY SERVICES AND CONTENT</b></p>
                                <p className='card-mid-p'>When you use the Platform You may be able to view content from third parties and may be able to access websites and services from the third-party ("Third Party Content"). Unless specifically not stated otherwise, MYFITNESSCOACH does not control and does not endorse or accept the Third Party Content and has no obligation to provide Third Party Content, including material that could be inaccurate, insufficient or inaccurate or indecent, offensive, or otherwise in violation of your region.</p>
                                <p className='card-mid-p'>Your interactions or correspondence with third parties is exclusively with the other party. MYFITNESSCOACH cannot be held responsible for any damage or loss caused by any dealings with third parties, and you agree that the access to Third Party Content and your interactions with third parties is entirely completely at risk.</p>
                                <p className='card-mid-p'>If you connect to the Platform by using or utilizing any software or services offered by third-party providers, you accept the fact that MYFITNESSCOACH is not liable or responsible for any damage or loss caused by or from your use of third-party software or services.</p>
                                <p className='blogs-h3'><b>LIMITATIONS OF LIABILITY, DISCLAIMER, INDEMNITY</b></p>
                                <p className='card-mid-p'>Limitation of Liability</p>
                                <ul className='blogs-list'>
                                    <li>The Terms and Conditions of Usage do not exclude or limit MYFITNESSCOACH's responsibility for (a) the death of a person or injury that results from MYFITNESSCOACH's negligence MYFITNESSCOACH or its associated Parties (as defined by Clause 7.1(b) in the following paragraph); (b) fraud or fraudulent misrepresentation (c) the other thing that is not exempted or restricted by Applicable Laws and (d) any of the indemnities laid up within Clause 7.3.</li>
                                    <li>As a result of the above, to the greatest extent allowed by applicable laws:</li>
                                    <li>in no case will MYFITNESSCOACH nor its affiliates nor its shareholders, members, directors, officers, employees and attorneys, representatives, agents, or contractors (collectively, <b>"Associated Parties"</b>) be held liable for any of the:</li>

                                </ul>
                                <p className='blogs-h3'>intangible or consequential loss or</p>
                                <p className='card-mid-p'>loss of profits, loss of business opportunity, expected savings, goodwill or revenue,in all cases, regardless of whether it is a result of a breach of contract or negligence (including negligence) or violation of statutory duty or any other reason arising from or relating to the authorized or non-authorized access to the Platform or the Terms of Service as well as</p>
                                <p className='card-mid-p'>MYFITNESSCOACH along with The Associated Parties' aggregate liability in tort, contract, or in any other manner (including any responsibility for negligent act or mistake) whatever the reason, arising out of or related to the fulfillment or observance of these terms of use with respect to any one or more events or events shall be limit to the higher amount of (a) all the fees received from MYFITNESSCOACH or Associated Parties from you in connection to using the Platform within the (12) months prior to the first or a series of incidents connected to it that led to a claim brought by you in accordance with these Terms of Use.</p>
                                <p className='blogs-h3'><b>Disclaimers</b></p>
                                <ul className='blogs-list'>
                                    <li>To the extent permissible within the limits of Applicable Laws, the Platform, as well as any other service, product, or other product offered through or for the benefit of MYFITNESSCOACH, is offered on the "as is" and "as available" basis. MYFITNESSCOACH specifically excludes, and you release any other warranty that is implied or expressly stated, implicit or expressly stated, such as implied warranties of the merchantability, fitness for specific purposes, or title or non-infringement warranties arising out of performance, trading, or other transactions. Without limiting the above, MYFITNESSCOACH does not represent or warrant that the Platform is complete, accurate, reliable, current, error-free, or free of harmful components or viruses.</li>
                                    <li>The features available on the Platform that encourage fitness, nutrition, or general well-being are provided for information only and are not intended to provide medical advice or services or for diagnosis or therapeutic purposes. Before participating in any exercise or making any modifications to your lifestyle or eating habits, We recommend seeking advice from your medical professional. Don't disregard advice given by medical professionals in light of what you've found through the Platform. To the extent that is permitted in accordance with Applicable Laws, MYFITNESSCOACH is not responsible for any damage or loss whatsoever that may arise from your usage of, or the inability to utilize, the features available on the Platform.</li>
                                </ul>
                                <p className='blogs-h3'><b>Indemnification</b></p>
                                <p className='card-mid-p'>To the extent allowed to the maximum extent permitted by Applicable Laws, You agree to indemnify and keep the MYFITNESSCOACH immediately on demand along with MYFITNESSCOACH and the Associated Parties from any claim for, demand, lawsuit or action inquiry, liability or expense, or loss, including reasonable attorney's costs, in any manner that arises out of, or related to, or relating to or directly or indirectly:</p>
                                <ul className='blogs-list'>
                                    <li>your behavior or use of the Platform, or your conduct in connection with your use of, or conduct on the Platform;</li>
                                    <li>Your breach or violation Use or any other policy</li>
                                    <li>the Contributed Content that you submit the Contributed Content you provide</li>
                                    <li><p>the infraction of your violation of any Applicable Laws or the rights of a third individual or the.</p><p>You agree to offer MYFITNESSCOACH, along with The Associated Parties, any assistance that MYFITNESSCOACH and the Associated Parties reasonably request in defense of any such action or proceeding.</p></li>
                                </ul>
                                <p className='blogs-h3'><b>CHANGES AND UPDATES TO THE PLATFORM, TERMS OF USE</b></p>
                                <p className='card-mid-p'>MYFITNESSCOACH may end or modify any feature or portion on its Platform at any time and without notification. MYFITNESSCOACH may also modify and amend these Terms of Service at any time at the sole discretion of MYFITNESSCOACH by putting the most current Terms of Use in the Platform. Any modifications to the Terms of Service are effective immediately upon posting on the Platform and are applicable to all access as well as use on the Platform (including orders made on the Platform) following the posting. The continued usage of the Platform after the posting of the revised Terms of Service signifies your acceptance and consent to the new terms, which will be in effect for you.</p>
                                <p className='blogs-h3'><b>COMPLAINTS, GOVERNING LAW AND DISPUTE RESOLUTION</b></p>
                                <p className='card-mid-p'>If you have any questions, feedback, or complaints, please contact the MYFITNESSCOACH team at the following email address: contact@myfitnesscoach.fit or through the chat feature on the MYFITNESSCOACH website.</p>
                                <h2 className='blogs-h2'>GENERAL</h2>
                                <p className='blogs-h3'><b>FORCE MAJEURE EVENT</b></p>
                                <p className='card-mid-p'>"Force Majeure" means a "Force Majeure Event" means any circumstance beyond MYFITNESSCOACH's control, including flooding, extraordinary weather conditions or earthquakes, or any other incident of God such as rebellion, fire, insurrection or riot, labor dispute incident, act of the government, communications power outages, or software failure.</p>
                                <p className='card-mid-p'>If a Force Majeure Event occurs that interferes with MYFITNESSCOACH's ability to perform its obligations in these Terms and Conditions, Use The MYFITNESSCOACH team will notify you as quickly as feasible to inform you of the event and the obligations of MYFITNESSCOACH under the Terms and Conditions will be canceled and the period of time for the performance of the obligations we have agreed to will extend to all durations of Force Majeure Event.</p>
                                <p className='blogs-h3'><b>Data Privacy</b></p>
                                <p className='card-mid-p'>We will only use your personal information as set out in MYFITNESSCOACH's Privacy Policy (as amended from time to time), available at the following webpage: _______________________</p>
                                <p className='blogs-h3'><b>Transfers</b></p>
                                <p className='card-mid-p'>MYFITNESSCOACH can assign its obligations and rights in these Terms of Use to a different entity. However, it will not alter the rights of you or MYFITNESSCOACH's obligations in these Terms of Use. You are not able to transfer your rights or obligations under these Terms of Service to anyone else.</p>
                                <p className='blogs-h3'><b>Validity</b></p>
                                <p className='card-mid-p'>If any provision in these Terms of Service is found to be illegal, invalid, or unenforceable, the provision will be considered to be severed from the rest of the provisions and shall have no effect on their validity or enforceability.</p>
                                <p className='blogs-h3'><b>Waiver</b></p>
                                <p className='card-mid-p'>A modification of any clause of this agreement is valid unless it is in written form and signed by the parties. Failure to make any provision or right under this agreement does not constitute an admission of any right or remedy to be enforced this remedy in the future or of any other remedy or right.</p>
                                <p className='blogs-h3'><b>The relationship between the parties</b></p>
                                <p className='card-mid-p'>The Terms and Conditions of Use (a) is designed to create or be construed to create any partnership or joint venture, agency, or trusteeship or (b) will create an obligation of fiduciary, equitable, or other obligation due to you by MYFITNESSCOACH towards you.</p>
                                <p className='blogs-h3'><b>Rights of third parties</b></p>
                                <p className='card-mid-p'>Any person who is not an aforementioned party to these Terms of Service has no legal right, whether based on applicable law or otherwise, to apply any of the terms in these Terms of Us.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}