import axios from "axios";
import global_pointer from "../components/Config";
const api = global_pointer.api;
export class servicex {
    getBlogsPage(category, from_, to_) {
        return (
            axios.get(api+"blogs/page/"+category+"?from="+from_+"&to="+to_)
        )
    }
    getBlogsByOffset(category, from_, to_) {
        return (
            axios.get(api+"blogs/range/"+category+"?from="+from_+"&to="+to_)
        )
    }
    getBlogSingle(id, category) {
        return (
            axios.get(api+"blogs/single/"+id+"?category="+category)
        )
    }
    sendEmail(email) {
        return axios.post(api+"blogs/email", { email: email })
    }
}
