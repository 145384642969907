import global_pointer from "../components/Config";
import { useLocation } from "react-router-dom";
export class GlobalMethods {
    pathname_ = useLocation().pathname.replace("/", "").split("/")[0];
    static current_sec =
        this.pathname_ == "about-us" ?
            "about-us-section"
            :
            this.pathname_ == "blogs" ?
                "blogs-section"
                :
                this.pathname_ == "" || this.pathname_ == "home" ?
                    "hero-section"
                    :
                    "error"
    url = global_pointer.url;
    formatDesc = (html) => {
        if (!html) return;
        let el = document.createElement('div');
        el.innerHTML = html;
        let desc_container = el.querySelector('.blogs-text-wraper .card-mid-p:first-of-type');
        let normalized_desc = desc_container?.innerHTML;
        return normalized_desc;
    }
    formatDate = (date) => {
        let d = new Date(date);
        return d?.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    sharer = (title, id, type, category) => {
        let pageLink = `${window.location.host}/blogs/${category}/${title.toLowerCase().replaceAll(" ", "-")}/${id}`;
        let link =
            type == "facebook" ?
                `http://www.facebook.com/sharer.php?u=${pageLink}&quote=${title}`
                :
                type == "twitter" ?
                    `https://twitter.com/intent/tweet?text=${title}&url=${pageLink}`
                    :
                    type == "linkedin" ?
                        `https://www.linkedin.com/sharing/share-offsite/?url=${pageLink}`
                        :
                        type == "pinterest" ?
                            `https://pinterest.com/pin/create/button/?url=${pageLink}&description=${title}`
                            :
                            type == "instagram" ?
                                `https://instagram.com`
                                :
                                ''
        window.open(link, "_blank");
    }
    openBlog = (title, id, category) => {
        window.open("/blogs/" + category + "/" + title?.toLowerCase()?.replaceAll(" ", "-") + "/" + id, "_blank")
    }
    snapTo(id) {
        if (id == "error-page") return;
        if (!id) return;
        var element = document.querySelector("#" + id);
        var headerOffset = 100;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
    handleNavigation = (event) => {
        var current_section = event.target.getAttribute("data-section");
        GlobalMethods.current_sec = current_section;
        if (current_section == "blogs-section") {
            return;
        }
        let section = document.querySelector("#" + current_section);
        if (section) {
            this.snapTo(current_section);
        }
    }
    validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            .test(String(email)
                .toLowerCase())
    };
    recentArticle(array) {
        if (!array || !Object.entries(array).length) return;
        const dates = array?.map(obj => new Date(obj.createdAt).getTime());
        const maxIndex = dates?.indexOf(Math.max(...dates));
        const mostRecentArticle = array?.[maxIndex];
        return mostRecentArticle
    }
    trendingArticles(array) {
        if (!array || !Object.entries(array).length) return;
        let blogs = [...array]
        const trending_articles = blogs.sort((a,b)=>{
            return b.trending_count - a.trending_count;
        })
        return trending_articles
    }
}