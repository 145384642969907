import React, { useEffect, useState } from "react";
import './SingleBlog.css'
import global_pointer from "../components/Config";
import { useParams } from "react-router-dom";
import { servicex } from "../services/Api"
import { GlobalMethods } from "../services/GlobalMethods";
import SocialBar from "../components/utils/SocialBar";
import Spinner from "../components/utils/Spinner"
import NotFount from "../components/NotFount";
import ACarousel from "../components/utils/AliceCarousel";
export default function SingleBlog() {
    const methods = new GlobalMethods();
    const imgURL = global_pointer.imgURL;
    const service = new servicex()
    const { id, category } = useParams();
    const [pageNotFound, setPageNotFound] = useState(false);
    const [faq, setFaq] = useState([]);
    const [data, setData] = useState([]);
    const [singleBlog, setSingleBlog] = useState(Object);
    const [showSpinner, setShowSpinner] = useState(true)
    GlobalMethods.current_sec = "blogs-section"
    function getBlogs() {
        service.getBlogSingle(id, category).then(x => {
            if (x?.data && Object.entries(x?.data)?.length) {
                let arr_ = []
                var single_ = x.data.blog
                if (single_?.catagory != category) {
                    setPageNotFound(true)
                    return;
                }
                setData(x.data.related);
                setSingleBlog(single_)
                single_?.faq?.forEach(el => {
                    let json = JSON.parse(el);
                    arr_.push(json)
                })
                setFaq(arr_);
            }
        }).catch(error => {
            console.error("custom-error:", error)
        })
    }
    useEffect(() => {
        getBlogs()
        let desc_container = document.querySelector('#article-description')
        const observer = new MutationObserver((e) => {
            setShowSpinner(false)
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                let links = desc_container.querySelectorAll('a[href*=play\\.google\\.com]');
                links.forEach(x => {
                    x.setAttribute('href', 'https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195')
                })
            } else {
                let links = desc_container.querySelectorAll('a[href*=apps\\.apple\\.com]');
                links.forEach(x => {
                    x.setAttribute('href', 'https://play.google.com/store/apps/details?id=com.myfitnesscoach')
                })
            }
            desc_container.querySelectorAll('a').forEach(x => x.setAttribute('target', '_blank'))
        })
        const config = {
            childList: true
        }
        observer.observe(desc_container, config)
    }, [])
    return (
        pageNotFound ?
            <>
                <NotFount inside />
            </>
            :
            <>
                <Spinner show={showSpinner} duration={1000} />
                <section id="blogs-section" className="">
                    <div className="container blogs-container">
                        <section className="blog-content">
                            <div className="content-card d-flex flex-column rg" id="article-content">
                                <h1 id="article-title" className="card-heading">{singleBlog?.title}</h1>
                                <div className="big-img-wraper">
                                    <img  id="article-image" src={imgURL + singleBlog?.imagepath} width="100%"
                                        alt={singleBlog.meta_keyword}
                                        className="blogs-big-img w-full" />
                                </div>
                                <div className="sub-heading-wraper big-card-wraper d-flex justify-content-between">
                                    <span className="big-card-date">By: <b> {singleBlog?.author} </b> &#183;
                                        {methods.formatDate(singleBlog?.createdAt)}</span>
                                    <SocialBar id={singleBlog?._id} title={singleBlog?.meta_keyword} category={singleBlog?.catagory} />
                                </div>
                                <div id="article-description" dangerouslySetInnerHTML={{ __html: singleBlog?.description }}>
                                </div>

                                {faq?.length ?
                                    <div className="faq-section d-flex flex-column rg">
                                        <h2 className="blogs-h2">Frequently Asked Questions</h2>
                                        <div className="accordion w-100 mt-3" id="basicAccordion">
                                            {faq?.map((q, i) => (
                                                <div className="accordion-item" key={i}>
                                                    <h2 className="accordion-header" id={"heading" + i}>
                                                        <button className="accordion-button collapsed faq-head" type="button" data-bs-toggle="collapse"
                                                            data-bs-target={"#basicAccordionCollapse" + i} aria-expanded="false" aria-controls={"collapse" + i}>
                                                            {q.question}
                                                        </button>
                                                    </h2>
                                                    <div id={"basicAccordionCollapse" + i} className="accordion-collapse collapse" aria-labelledby={"heading" + i}
                                                        data-bs-parent="#basicAccordion" >
                                                        <div className="accordion-body faq-ans" dangerouslySetInnerHTML={{ __html: q.answer }}>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                                {
                                    data?.length ?
                                        <section className="single-blog-cards-bottom mt-5rem overflow-hidden">
                                            <h2 className="blogs-h2">Related Articles</h2>

                                            <div className="blog-end-cards d-flex cg pt-5 pb-3 px-" style={{ width: "100%" }}>
                                                <ACarousel data={
                                                    data?.map((blog, i) => (
                                                        <div className="all-articles-card  d-flex flex-column rg" key={i} style={{ flexShrink: "0", width: '98%' }}>
                                                            <div className="blogs-random-card d-flex flex-column rg">
                                                                <img  className="random-card-img w-100" src={global_pointer.imgURL + blog.imagepath} alt={blog.meta_keyword} />
                                                                <span className="big-card-date">By: <b> {blog.author} &#183;
                                                                    {methods.formatDate(blog.createdAt)}</b>
                                                                </span>
                                                            </div>
                                                            <div className="big-card-heading d-flex position-relative">
                                                                <h2 className="inner-sm-heading  cursor-pointer text-cut-3"
                                                                    onClick={(event) => { methods.openBlog(blog.meta_keyword, blog._id, blog.catagory) }}>{blog.title}</h2>
                                                            </div>
                                                            <p className="inner-sm-description text-cut-3" dangerouslySetInnerHTML={{ __html: methods.formatDesc(blog.description) }}></p>
                                                            <div className="blog-card-readm d-flex align-items-center cg cursor-pointer"
                                                                onClick={(event) => { methods.openBlog(blog.meta_keyword, blog._id, blog.catagory) }}>
                                                                <p>Read More</p>
                                                                <img  className="blogs-card-arrow" src="/assets/blogs-arrow.png" />
                                                            </div>
                                                            <SocialBar id={blog?._id} title={blog?.meta_keyword} category={blog?.catagory} />
                                                        </div>
                                                    ))
                                                } />
                                            </div>
                                        </section>
                                        :
                                        ""
                                }
                            </div>
                        </section>
                    </div>
                </section>

            </>
    )
}